<template>
  <div class="wrapper">
    <Section class="contArRes">
      <div class="left-Col">
        <p>
          Bem vindo <br />
          <span> {{ userName }} </span>
        </p>

        <router-link
          v-for="route in getUserRoutes"
          :key="route.name"
          exact
          exactPath
          :to="/user/ + userName + '/' + route.path"
        >
          {{ route.props.name }}
        </router-link>

        <p v-if="!user.isCaetanoLogin" @click="userLogout">Terminar Sessão</p>
      </div>
      <div class="rigth-col">
        <router-view></router-view>
      </div>
    </Section>
  </div>
</template>
<script>
import Section from "@/components/Section/Section.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "UserPage",
  created() {
    this.userName == undefined && this.$router.push("/");
  },
  components: {
    Section,
  },
  updated() {
    this.userName == undefined && this.$router.push("/");
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      userName: "user/getUserName",
    }),
    getUserRoutes() {
      // get all routes, find the user's children routes and return all where props.name != false
      const userChildRoutes = this.$router.options.routes
        .find((r) => r.path == "/user/:id")
        ?.children.filter((child) => child.props.name);
      return userChildRoutes;
    },
  },
  methods: {
    userLogout() {
      this.$store.dispatch("user/logout");
    },
  },
};
</script>
<style lang="scss" scoped>
$paddingM: 2.5rem;
$paddingD: 5rem;

.contArRes {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 2rem;
  margin: 0 !important;
}

.left-Col {
  flex-basis: 30%;
  min-height: 32rem;
  padding: $paddingM 2rem $paddingM 3rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  background: $tmeGrey6Transp;
  color: $primaryColor;
  position: relative;

  > .router-link-active {
    font-family: $fontSemiBold;
  }

  > * {
    color: $primaryColor;
    text-decoration: none;
    user-select: none;
    font-size: 1.125rem;
    text-align: left;

    &:first-child {
      margin-bottom: 1rem;
    }
  }

  > p {
    &:first-of-type {
      user-select: none;
      font-size: 2.125rem;
      line-height: 2.5rem;

      > span {
        font-family: $fontSemiBold;
        overflow-wrap: break-word;
      }
    }

    &:last-of-type:not(:only-of-type) {
      cursor: pointer;
    }
  }

  > a,
  > p:last-of-type:not(:only-of-type) {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin-top: 2.5rem;
  }
}

.rigth-col {
  flex-basis: 65%;
  min-height: 335px;
  margin: 0;
  padding: 5rem 0;
}

@include respond-to-min(lg) {
  .contArRes {
    flex-flow: row;
  }

  .left-Col {
    padding: {
      top: $paddingD;
      bottom: $paddingD;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      right: 100%;
      left: -50%;
      background-color: $tmeGrey6Transp;
    }

    > p:first-of-type {
      max-width: 17rem;
    }

    > p:last-of-type:not(:only-of-type) {
      margin-top: auto;
    }
  }

  .rigth-col {
    padding: $paddingD 0;
  }
}

@include respond-to(lg, xl) {
  .left-Col {
    flex-basis: 35%;
  }
}
</style>
